<template lang="pug">
  div(ref="countupElement").about__counter-number {{ count }}
</template>

<script>
export default {
  name: 'CounterBlock',
  props: {
    targetNumber: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      count: 0,
      options: {
        threshold: 1.0
      }
    }
  },
  mounted () {
    this.observer = new IntersectionObserver(this.handleIntersection, this.options)
    this.observer.observe(this.$refs.countupElement)
  },
  methods: {
    handleIntersection (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.startCountUp()
          this.observer.unobserve(entry.target)
        }
      })
    },
    startCountUp () {
      const duration = 6000
      const increment = Math.ceil(this.targetNumber / (duration / 16))

      const animateCountUp = () => {
        if (this.count < this.targetNumber) {
          this.count = Math.min(this.count + increment, this.targetNumber)
          requestAnimationFrame(animateCountUp)
        }
      }
      requestAnimationFrame(animateCountUp)
    }
  }
}
</script>
